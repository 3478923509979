import {Form, Input, Modal} from 'antd';
import {AxiosError} from "axios";
import {omitBy} from 'lodash';
import React from 'react';
import DeviceApi from "../../Api/DeviceApi";
import DeviceDomain, {DeviceModel} from "../../Domain/DeviceDomain";

interface State {
    form: DeviceDomain,
    loading: boolean
}

interface Props {
    onCreateSuccess: (device: DeviceDomain) => void,
    onCreateFail: (error: AxiosError) => void,
    onVisibleChange: (visible: boolean) => void
}

class DeviceCreate extends React.Component<Props, State> {

    state = {
        form: {...DeviceModel},
        loading: false
    }

    handleChangeInput = (field: string, e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value
            }
        })
    };

    handleSubmit = () => {
        this.setState({loading: true});
        const data = omitBy(this.state.form, (value: any) => (value === "" || value === undefined || value === null));
        DeviceApi.create(data)
            .then((response) => {
                this.props.onCreateSuccess(response.data);
            })
            .catch((error: AxiosError) => {
                this.props.onCreateFail(error);
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    render() {
        const {form, loading} = this.state;
        return (
            <Modal
                visible={true}
                title={'Tạo thiết bị'}
                okText={"Xác Nhận"}
                closeIcon={<i className="fa-solid fa-xmark"  />}
                cancelText={"Hủy Bỏ"}
                width={660}
                onOk={this.handleSubmit}
                onCancel={() => this.props.onVisibleChange(false)}
                okButtonProps={{
                    loading: loading,
                    disabled: loading || !form.name,
                    tabIndex: 2,
				    icon: <i className="fa-solid fa-check pd-r-8" />
                }}
                cancelButtonProps={{
                    loading: loading,
                    disabled: loading,
                    tabIndex: 3,
                    icon: <i className="fa-solid fa-xmark pd-r-8" />,
                    type: 'ghost'
                }}
				centered
                >
                <Form>
                    <Form.Item
                        label={"Tên thiết bị"}
                        className="mg-bt-0"
                        name="name"
                        rules={[{required: true, message: 'Tên thiết bị không được để trống'}]}
                    >
                        <Input
                            autoFocus={true}
                            tabIndex={1}
                            className={'width-100pc'}
                            placeholder="Vui lòng nhập tên thiết bị"
                            value={form.name}
                            disabled={loading}
                            onChange={this.handleChangeInput.bind(this, 'name')}
                            onPressEnter={() => this.handleSubmit()}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default DeviceCreate;
