export default interface DeviceDomain {
    code?: string,
    name?: string,
    pairingToken?: string,
    wlanMac?: string,
    bluetoothMac?: string,
    status?: string,
    os?: string,
    deviceId?: string,
    secret?: string,
}

export const DeviceModel: DeviceDomain = {
    code: "",
    name: "",
    pairingToken: "",
    wlanMac: "",
    bluetoothMac: "",
    status: "",
    os: "",
    deviceId: "",
    secret: "",
}